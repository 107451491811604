import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../App'
import { useGet } from '../hooks/useGet'
import { RadSelect } from '../common/RadSelect'
import { translations } from '../translations'
import '../form.css'
import '../layout.css'
import '../responsive.css'

export function Home () {
  const [isOpen, setIsOpen] = useState(false)
  const { language, setLanguage } = useContext(AppContext)

  function translate (text) {
    if (language === 'Spanish') {
      if (translations[text] == null) console.warn('No translation found for', text)
      return translations[text] ?? text
    } else {
      return text
    }
  }

  const links = (
    <>
      <li>{translate('HOME')}</li>
      <li><Link to='/apply' role='button'>{translate('APPLICATION')}</Link></li>
      <li><Link to='/cherokee-nation-benefits' role='button'>{translate('CHEROKEE NATION BENEFITS')}</Link></li>
      <li><Link to='/chickasaw-nation-benefits' role='button'>{translate('CHICKASAW NATION BENEFITS')}</Link></li>
      <li>
        <select
          onChange={(event) => {
            setLanguage(event.target.value)
            setIsOpen(false)
          }}
          id='language-menu'
          ariay-labelledby='language-menu-label'
          name='language-menu'
          value={language}
        >
          <option value='English'>{translate('ENGLISH')}</option>
          <option value='Spanish'>{translate('SPANISH')}</option>
        </select>
      </li>
    </>
  )

  return (
    <div>
      <section className='main_div main_menu_wrap menu_section_wrap'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12 mnu_top text-center'>
              <ul className='web_nav'>
                {links}
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className='responsive_header white-background' id='back_top_menu'>
        <div className='container-fluid px-lg-5'>
          <div className='row pding align-items-center'>
            <div className='col-8 ps-5' />
            <div className='mobile_icon_wrap col-4' onClick={() => setIsOpen(!isOpen)}>
              <div className='mobile_icon'>
                <i className='fas fa-bars' aria-hidden='true' />
              </div>
            </div>
          </div>
        </div>
        <ul id={isOpen ? 'open_nav' : null} className='top_menu responsive_header_nav pt-5 web_nav_wrap'>
          {links}
        </ul>
      </div>
      <div className='menu_holder' />

      <section className='main_div index_banner_wrap'>
        <div className='back_img_wrap'>
          <img src='assets/images/index_banner_converted.webp' alt='man holding small child' />
        </div>
        <div className='container banner_main_wrap'>
          <div className='row'>
            <div className='col-lg-8 ms-auto'>
              <div className='banner_content_wrap'>
                <h1>{translate('NOURISHING OUR FUTURE')}</h1>
                <h2>{translate('Ensuring Every Child Enjoys a Healthy Summer with EBT')}</h2>
                <p>{translate('Beginning in summer 2024, families can receive a food package valued at $120 per eligible child in the Muscogee Nation Reservation.')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='main_div index_what_wrap'>
        <div className='container-fluid pt-lg-5 px-lg-5'>
          <div className='row align-items-center px-lg-5'>
            <h2 style={{ whiteSpace: 'nowrap' }}>{translate('What Is')}&nbsp;</h2>
            <div className='col-lg-6 ps-lg-0'>
              <h2>{translate('Summer EBT?')}</h2>
              <h3>{translate('A New Federal Program For Families')}</h3>
              <p>{translate('Summer EBT is a new federal program that provides benefits to buy groceries to qualifying families with school-aged children when schools are closed for the summer. Summer EBT benefits come in the form of pre-loaded cards (like a debit card) that families can use to purchase groceries at participating stores. Beginning in summer 2024, families can receive a food package valued at $120 per eligible child in the Muscogee Nation Reservation. These benefits work together with other available   programs, such as summer meals for kids, SNAP (formerly known as food stamps), WIC, and Food Distribution Program on Indian Reservation (FDPIR or commodities) to help ensure children have consistent access to critical nutrition when school is out.')}
              </p>
            </div>
            <div className='col-lg-6'>
              <img src='assets/images/fruit_converted.webp' alt='fruit' />
            </div>
          </div>
        </div>
      </section>

      <section className='main_div unitin_ng_maiwrap'>
        <div className='graphic_border px-lg-3'>
          <img className='w-100' src='assets/images/graphic_border_converted.webp' alt='graphic border' />
        </div>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12'>
              <h3><strong>{translate('Uniting for Nutrition')}:</strong> {translate('The Summer EBT Program in the Muscogee Nation')}</h3>
              <p>{translate('The Muscogee Nation is partnering with the Chickasaw Nation, the Cherokee Nation, Hunger Free Oklahoma, and United States Department of Agriculture (USDA) to introduce the Summer Electronic Benefit Transfer Program for Children (Summer EBT) to the Muscogee Nation Reservation. Summer EBT provides additional nutritional support to eligible children during the summer months when they do not have access to school meals. About 105,000 children are eligible to receive a food package valued at $120 during the summer in Creek, Hughes, Okfuskee, Okmulgee, McIntosh, Muscogee, Tulsa, and Wagoner counties. Both the Cherokee Nation and the Chickasaw Nation have been operating Summer EBT in their own reservations for multiple years. By partnering with them, the Muscogee Nation is leveraging their expertise to provide program benefits to Muscogee families. Hunger Free Oklahoma is supporting the initiative by providing customer service to program participants.')}
              </p>
            </div>

            <div className='col-lg-3'>
              <img src='assets/images/partner1_converted.webp' alt='Muscogee Nation logo' />
            </div>

            <div className='col-lg-3'>
              <img src='assets/images/partner2_converted.webp' alt='The Chickasaw Nation logo' />
            </div>

            <div className='col-lg-3'>
              <img className='cherokee' src='assets/images/image003.png' alt='Seal of the Cherokee Nation' />
            </div>

            <div className='col-lg-3'>
              <img src='assets/images/partner4_converted.webp' alt='Hunger Free Oklahoma logo' />
            </div>

          </div>
        </div>
      </section>

      <section className='main_div '>
        <div className='container-fluid eligibility_blue'>
          <div className='row align-items-center pb-lg-4'>
            <div className='col-lg-5'>
              <img src='assets/images/child_group_converted.webp' alt='group of children' />
            </div>

            <div className='col-lg-7 ps-lg-4'>
              <h2>{translate('Summer EBT Eligibility')}</h2>
              <p role='presentation'>{translate('To be eligible to receive Summer EBT through Muscogee Nation, a student must attend an eligible school district, AND one of the following')}:</p>
              <ul>
                <li>{translate('Participate in the Free and Reduced-Price Meal Program at school, or')}</li>
                <li>{translate('Meet the income guidelines for Free and Reduced Price Meals, or')}</li>
                <li>{translate('Participate in SNAP (food stamps), WIC, SoonerCare (Medicaid), Food Distribution on Indian Reservation (FDPIR or commodities), or TANF (Temporary Assistance for Needy Families), or')}</li>
                <li>{translate('Be enrolled in foster care')}</li>
              </ul>

              <p>{translate('Children living within the Muscogee Reservation who attend virtual charter schools or are homeschooled might be eligible if they meet one of the following criteria:')}</p>
              &nbsp;
              <ul>
                <li>
                  {translate('Participate in SNAP (food stamps), WIC, SoonerCare (Medicaid), Food Distribution on Indian Reservation (FDPIR or commodities), or TANF (Temporary Assistance for Needy Families), or')}
                </li>
                <li>{translate('Be enrolled in foster care')}</li>
              </ul>

            </div>
          </div>
        </div>
        <div className='container-fluid green_info_area eligibility_blue'>
          <p>{translate('Select your student\'s school district to learn more about the benefits available to you and how to apply for benefits. If your student\'s school district is not on this list, unfortunately, they are not eligible for Summer EBT through the Muscogee Nation.')}
            <br /><br />
          </p>
        </div>
        <div className='container-fluid green-background'>
          <div className='text-center check-eligibility'>
            <div className='check_anchor'>
              <CheckSchoolEligibility translate={translate} />
            </div>
          </div>
        </div>
      </section>

      <section className='main_div feature_info'>
        <div className='grid-container px-0'>
          <div className='item'>
            <img src='assets/images/feature1_converted.webp' alt='apples' />
          </div>
          <div className='item'>
            <img src='assets/images/feature2_converted.webp' alt='children' />
          </div>
          <div className='item'>
            <img src='assets/images/feature3_converted.webp' alt='produce on shelf' />
          </div>
          <div className='item'><img src='assets/images/feature4_converted.webp' alt='peeled oranges' /></div>
        </div>

        <div className='container-fluid px-lg-5 feature_points_wrap'>
          <div className='row align-items-center'>
            <div className='col-lg-8'>
              <h2>{translate('Key Features of the Program')}:</h2>
              <p>{translate('The Summer Electronic Benefit Transfer (EBT) Program provides a support system for families with school-aged children for the months of June, July, and August, when access to free or reduced-cost school meals is unavailable. This initiative is federally funded and designed to alleviate the nutritional gap experienced by children during the summer break.')}</p>
              <ul>
                <li><strong>{translate('Open Applications')}:</strong> {translate('Families are encouraged to apply now to take advantage of this beneficial program')}.</li>
                <li><strong>{translate('Eligibility Criteria')}:</strong> {translate('This program serves Native American and non-native students who meet the eligibility requirements listed under Summer EBT Eligibility')}.</li>
                <li><strong>{translate('Financial Assistance')}:</strong> {translate('Qualified families will receive a food package valued at $120 per eligible child for the summer. This financial assistance is aimed at purchasing healthy food options')}.</li>
                <li><strong>{translate('EBT Cards')}:</strong> {translate('The benefits are loaded onto EBT cards, functioning similarly to debit cards, making the purchase of groceries seamless at participating stores')}</li>
                <li><strong>{translate('No Impact on Other Assistance')}:</strong> {translate('Enrollment in the Summer EBT Program does not affect the eligibility or benefits of other government assistance programs that the family or child might be receiving')}.</li>
                <li> <strong>{translate('Confidentiality and Privacy')}:</strong> {translate('The program guarantees privacy. Information is solely used for the purpose of the Summer EBT Program')}.</li>
                <li><strong>{translate('Ease of Use')}:</strong> {translate('Upon approval, families will receive their Summer EBT cards through mail, along with a list of participating stores and a food card, which serves as a shopping guide for approved food items')}.</li>
              </ul>
            </div>

            <div className='col-lg-4'>
              <img src='assets/images/feature_points_converted.webp' alt='carrots' />
            </div>
          </div>
        </div>

      </section>

      <footer className='main_div footer_wrap'>
        <div className='container-fluid px-lg-5'>
          <ul className='footer_web_nav'>
            {links}
          </ul>
          <div className='row '>
            <div className='col-lg-4 first_info_para'>
              <p>{translate('In accordance with federal civil rights law and U.S. Department of Agriculture (USDA) civil rights regulations and policies, this institution is prohibited from discriminating on the basis of race, color, national origin, sex (including gender identity and sexual orientation), disability, age, or reprisal or retaliation for prior civil rights activity.  This institution is an equal opportunity provider')}.</p>
            </div>
            <div className='col-lg-4  contact_info_wrap'>
              <h3>{translate('Contact Info')}</h3>
              <div className='row justify-content-center '>
                <div className='col-lg-4 text-center px-0 mx-0'>
                  <img src='assets/images/footer_phone.png' alt='phone' />
                </div>
                <div className='col-lg-8 px-0 mx-0'>

                  <p>{translate('For assistance or to apply over the phone please call the')} <b>{translate('Muscogee')}</b> {translate('Summer EBT Helpline')}</p>
                  <p><a href='tel:918-213-4855' target='_blank' rel='noreferrer'>918-213-4855 </a></p>
                  <p>{translate('9AM to 6PM Monday through Friday')}</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 calling_wrap contact_info_wrap'>
              <div className='row '>
                <div className='col-lg-4 pe-0 text-center'>
                  <img src='assets/images/footer_phone.png' alt='phone' />
                </div>
                <div className='col-lg-8 px-0'>
                  <p>{translate('If you are calling about a')} <b>{translate('Cherokee Nation')}</b> {translate('Summer EBT application, please call')}</p>
                  <p className=''><a href='tel:539-234-3265' role='button'>539-234-3265</a></p>
                </div>
                <div className='col-lg-4 text-center pe-0'>
                  <img src='assets/images/footer_phone.png' alt='phone' />
                </div>
                <div className='col-lg-8 px-0'>
                  <p>{translate('If you are calling about a')} <b>{translate('Chickasaw Nation')}</b> {translate('Summer EBT application, please call')}</p>
                  <p><a href='tel:844-256-3467' role='button'>844-256-3467</a><span> or </span><a href='tel: 580-272-1178' role='button'>580-272-1178</a></p>
                </div>
              </div>
            </div>
            <p><a className='white' href='/admin' role='button'>{translate('Administrator Access')}</a></p>
          </div>
        </div>
      </footer>
    </div>
  )
}

export function CheckSchoolEligibility ({ translate }) {
  const [schoolDistrict, setSchoolDistrict] = useState()
  const { data: schoolDistrictOptions } = useGet('/api/option/school-district')

  if (schoolDistrictOptions != null) {
    const filteredSchoolDistrictOptions = schoolDistrictOptions.filter(x => x.value !== '')

    return (
      <div className='check-eligibility'>
        <h1>{translate('Check School Eligibility')}</h1>
        <RadSelect
          filteringType='auto'
          selectedOption={schoolDistrictOptions.find(x => x.value === schoolDistrict?.value)}
          onChange={({ detail }) => {
            setSchoolDistrict(detail.selectedOption)
          }}
          options={filteredSchoolDistrictOptions}
          enteredTextLabel={value => value}
          selectedAriaLabel={translate('Selected')}
          placeholder={translate('Select a school district')}
          empty={translate('No matches found')}
        />
        <div className='result'>
          {schoolDistrict != null && schoolDistrict.tribalArea === 'Cherokee' && schoolDistrict.ebtProvider === 'Cherokee' &&
            <span>
              {translate('This school district is in the Cherokee Nation Reservation.')}
              <br />
              {translate('Click')} <a href='https://gadugiportal.cherokee.org/s/wics-program' target='_blank' rel='noopener noreferrer'>{translate('here')}</a> {translate('for more information about the Cherokee Nation Summer EBT program.')}
            </span>}
          {schoolDistrict != null && schoolDistrict.tribalArea === 'Chickasaw' && schoolDistrict.ebtProvider === 'Chickasaw' &&
            <span>
              {translate('This school district is in the Chickasaw, Choctaw, or Seminole Nation Reservation.')}
              <br />
              {translate('Click')} <a href='https://chickasaw.net/summerebt' target='_blank' rel='noopener noreferrer'>{translate('here')}</a> {translate('for more information about the Chickasaw Nation Summer EBT program.')}
            </span>}
          {schoolDistrict != null && schoolDistrict.tribalArea != null && schoolDistrict.tribalArea !== 'Cherokee' && schoolDistrict.tribalArea !== 'Chickasaw' &&
            <span>
              {translate('This school district is part of this program and your student may be eligible for Summer EBT benefits through this program.')}
              {schoolDistrict.dataSharing === true && ' ' + translate('This school is participating in data sharing for this program and your student may be automatically enrolled if they receive free or reduced priced meals.')}
              <br />
              {translate('If you\'d like to apply for benefits, click')} <a href='/apply'>{translate('here')}</a>
            </span>}
          {schoolDistrict != null && schoolDistrict.tribalArea == null &&
            <span>
              {translate('Unfortunately, the selected school is not eligible for Summer EBT benefits. You may be eligible for other nutrition assistance programs. Find out more')}
              &nbsp;<a href='https://www.hungerfreeok.org/foodresourcetool' target='_blank' rel='noreferrer'>{translate('here')}</a>.
            </span>}
        </div>
      </div>
    )
  }
}
