
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_rjqu5_zu8o5_5",
  "header-text": "awsui_header-text_rjqu5_zu8o5_6",
  "open-button": "awsui_open-button_rjqu5_zu8o5_7",
  "close-button": "awsui_close-button_rjqu5_zu8o5_8",
  "preferences-button": "awsui_preferences-button_rjqu5_zu8o5_9",
  "slider": "awsui_slider_rjqu5_zu8o5_10",
  "open-position-bottom": "awsui_open-position-bottom_rjqu5_zu8o5_11",
  "open-position-side": "awsui_open-position-side_rjqu5_zu8o5_12"
};
  